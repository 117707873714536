interface Icons {
  [key: string]: string;
}

export const icons: Icons = {
  times:
    'M24 18.343L42.343 0 48 5.657 29.657 24 48 42.343 42.343 48 24 29.657 5.657 48 0 42.343 18.343 24 0 5.657 5.657 0 24 18.343z',
  'exclamation-circle':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm4,32l-8,0l0,8l8,0l0,-8Zm0,-24l-8,0l0,20l8,0l0,-20Z',
  'question-circle':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm2,32l-0.827,0l-7.173,0l0,8l8,0l0,-8Zm0,-2l0,-2.5c8.915,0.447 11.351,-4.185 11.332,-9.313c-0.037,-9.955 -11.565,-10.182 -11.696,-10.168c0,0 -7.636,-0.019 -7.636,-0.019l0,7l8.207,0c0.015,-0.001 4.004,-0.322 4.004,2.992c0,3.321 -3.97,3.014 -4.035,3.008l-8.176,0l0,9l8,0Z',
  'check-circle':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm-4.173,28.075l17.261,-16.676l5.559,5.754l-23.014,22.234l-0.001,-0.001l-0.001,0.001l-12.837,-13.287l5.754,-5.559l7.279,7.534Z',
  'no-entry-circle':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm0.215,8.001c9.003,0.171 16.932,8.999 15.638,18.235c-1.058,7.549 -8.185,13.764 -15.845,13.764c-10.662,0 -19.612,-12.816 -14.249,-23.282c2.636,-5.143 8.163,-8.605 14.042,-8.717c0.207,-0.001 0.207,-0.001 0.414,0Zm-9.697,10.073c-2.027,3.179 -2.671,7.245 -0.901,10.917c2.795,5.797 10.986,8.026 16.537,4.719l-15.636,-15.636Zm19.168,12.041c3.748,-6.614 0.068,-17.457 -9.528,-17.64c-0.15,-0.001 -0.15,-0.001 -0.3,0c-2.119,0.041 -4.13,0.769 -5.851,1.96l15.679,15.68Z',
  user:
    'M38.59,24c5.72,5.525 9.41,6.232 9.41,16c0,0 0,0 0,0c-14.226,10.656 -33.774,10.656 -48,0c0,0 0,0 0,0c0,-9.768 3.69,-10.475 9.41,-16c2.509,7.466 8.101,8 14.59,8c6.489,0 12.081,-0.534 14.59,-8Zm-14.59,-24c7.682,0 13.919,6.237 13.919,13.919c0,7.682 -6.237,13.919 -13.919,13.919c-7.682,0 -13.919,-6.237 -13.919,-13.919c0,-7.682 6.237,-13.919 13.919,-13.919Z',
  'times-circle':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm0,18.335l10.343,-10.343l5.657,5.657l-10.343,10.343l10.351,10.351l-5.657,5.657l-10.351,-10.351l-10.343,10.343l-5.657,-5.657l10.343,-10.343l-10.335,-10.335l5.657,-5.657l10.335,10.335Z',
  search:
    'M31.027,36.684c-3.162,2.095 -6.953,3.316 -11.027,3.316c-11.038,0 -20,-8.962 -20,-20c0,-11.038 8.962,-20 20,-20c11.038,0 20,8.962 20,20c0,4.074 -1.221,7.865 -3.316,11.027l11.316,11.316l-5.657,5.657l-11.316,-11.316Zm-11.027,-28.684c6.623,0 12,5.377 12,12c0,6.623 -5.377,12 -12,12c-6.623,0 -12,-5.377 -12,-12c0,-6.623 5.377,-12 12,-12Z',
  radio:
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm0,8c8.831,0 16,7.169 16,16c0,8.831 -7.169,16 -16,16c-8.831,0 -16,-7.169 -16,-16c0,-8.831 7.169,-16 16,-16Z',
  'radio-checked':
    'M24,0c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24Zm0,8c8.831,0 16,7.169 16,16c0,8.831 -7.169,16 -16,16c-8.831,0 -16,-7.169 -16,-16c0,-8.831 7.169,-16 16,-16Zm0,8c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z',
  checkbox:
    'M36,0c6.623,0 12,5.377 12,12l0,24c0,6.623 -5.377,12 -12,12l-24,0c-6.623,0 -12,-5.377 -12,-12l0,-24c0,-6.623 5.377,-12 12,-12l24,0Zm-4,8l-16,0c-4.415,0 -8,3.585 -8,8l0,16c0,4.415 3.585,8 8,8l16,0c4.415,0 8,-3.585 8,-8l0,-16c0,-4.415 -3.585,-8 -8,-8Z',
  'checkbox-checked':
    'M36,48l-24,0c-6.623,0 -12,-5.377 -12,-12l0,-24c0,-6.623 5.377,-12 12,-12l24,0c6.623,0 12,5.377 12,12l0,24c0,6.623 -5.377,12 -12,12Zm-4,-40l-16,0c-4.416,0 -8,3.585 -8,8l0,16c0,4.415 3.585,8 8,8l16,0l0.052,0c4.391,-0.028 7.948,-3.602 7.948,-8l0,-16c0,-4.415 -3.584,-8 -8,-8Zm-11.473,17.904l12.25,-11.835l3.945,4.083l-16.332,15.779l-0.001,-0.001l-0.001,0.001l-9.11,-9.43l4.083,-3.944l5.166,5.347Z',
  'info-circle':
    'M24,48c-13.246,0 -24,-10.754 -24,-24c0,-13.246 10.754,-24 24,-24c13.246,0 24,10.754 24,24c0,13.246 -10.754,24 -24,24Zm-4,-32l8,0l0,-8l-8,0l0,8Zm0,24l8,0l0,-20l-8,0l0,20Z',
  'caret-down': 'M16,16l-16,-16l32,0l-16,16Z',
  'caret-up': 'M16,0l16,16l-32,0l16,-16Z',
  'lock-locked':
    'M5.057,19.905c0.621,-8.07 4.263,-16.077 12.483,-18.894c2.141,-0.733 4.415,-1.021 6.69,-1.011c5.289,0.077 10.49,2.17 13.837,6.458c2.993,3.832 4.453,8.612 4.848,13.447l5.085,0l0,28.116l-48,0l0,-28.116l5.057,0Zm21.193,22.116l0,-5.825c1.778,-0.845 3.009,-2.659 3.009,-4.758c0,-2.906 -2.359,-5.265 -5.265,-5.265c-2.906,0 -5.266,2.359 -5.266,5.265c0,2.099 1.231,3.913 3.01,4.758l0,5.825l4.512,0Zm10.619,-22.116c-0.829,-6.99 -4.806,-13.68 -12.357,-13.898c-3.316,-0.096 -6.814,0.868 -9.135,3.312c-2.703,2.846 -3.909,6.769 -4.276,10.586l25.768,0Z',
  'lock-unlocked':
    'M13.36,12.086l-6.633,0c1.869,-4.989 5.318,-9.192 10.813,-11.075c2.141,-0.733 4.415,-1.021 6.69,-1.011c5.289,0.077 10.49,2.17 13.837,6.458c3.583,4.588 4.97,10.535 4.96,16.307l4.973,0l0,28.116l-48,0l0,-28.116l37.033,0c0.025,-7.906 -3.94,-16.51 -12.521,-16.758c-3.316,-0.096 -6.814,0.868 -9.135,3.312c-0.797,0.839 -1.463,1.772 -2.017,2.767Zm12.89,32.795l0,-5.825c1.778,-0.845 3.009,-2.659 3.009,-4.758c0,-2.906 -2.359,-5.265 -5.265,-5.265c-2.906,0 -5.266,2.359 -5.266,5.265c0,2.099 1.231,3.913 3.01,4.758l0,5.825l4.512,0Z',
  shield:
    'M21.144,48.841c0,0 4.529,-1.817 9.394,-3.768c7.042,-2.824 11.75,-10.38 11.75,-18.857c0,-9.307 0,-19.776 0,-19.776c0,0 -5.755,-2.308 -11.26,-4.516c-6.396,-2.565 -13.372,-2.565 -19.768,0c-5.505,2.208 -11.26,4.516 -11.26,4.516l0,19.776c0,8.477 4.708,16.033 11.75,18.857c4.865,1.951 9.394,3.768 9.394,3.768Zm0,-45.96l0,43.079c0,0 -3.225,-1.294 -7,-2.808c-7.041,-2.824 -11.75,-10.38 -11.75,-18.857l0,-15.934c0,0 4.355,-1.747 8.866,-3.556c3.198,-1.283 6.541,-1.924 9.884,-1.924Z',
  filter: 'M29.259,24.988l0,15.012l-10.531,8l0,-23.029l-18.728,-24.971l48,0l-18.741,24.988Z',
  menu: 'M48,32l0,8l-48,0l0,-8l48,0Zm0,-16l0,8l-48,0l0,-8l48,0Zm0,-16l0,8l-48,0l0,-8l48,0Z',
  'chevron-left':
    'M0.024,20l-0.024,-0.023l21.061,-19.977l5.912,5.608l-15.173,14.392l15.173,14.392l-5.912,5.608l-21.061,-19.977l0.024,-0.023Z',
  'chevron-right':
    'M26.948,20l0.025,0.023l-21.061,19.977l-5.912,-5.608l15.173,-14.392l-15.173,-14.392l5.912,-5.608l21.061,19.977l-0.025,0.023Z',
};
