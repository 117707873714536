import { lighten } from 'polished';

export const color = {
  primary: '#006EF5',
  primaryButton: '#006EF5',
  focus: '#4799ff',
  secondary: '#ff6347',
  tertiary: '#DDDDDD',
  danger: '#dd2c2e',
  dangerButton: '#dd2c2e',
  warning: '#eba300',
  warningButton: '#eba300',
  warningButtonFont: '#290E00',
  info: '#2f83cf',
  infoButton: '#2A78BB',
  success: '#00854D',
  successButton: '#00854D',
  red: '#dd2c2e',
  yellow: '#eba300',
  blue: '#205A8E',
  green: '#00b76b',
  highlight: lighten(0.3, '#4799ff'),
  // Monochrome
  lightest: '#FFFFFF',
  lighter: '#F8F8F8',
  light: '#F3F3F3',
  mediumlight: '#EEEEEE',
  medium: '#DDDDDD',
  mediumdark: '#999999',
  dark: '#666666',
  darker: '#444444',
  darkest: '#333333',
  overlay: 'rgba(0,0,0,.5)',
  border: '#bcbcbc',
  darkModeBG: '#102A43',
  darkModeDarkest: '#102A43',
  darkModeDarker: '#243b53',
  darkModeDark: '#334e68',
  darkModeMediumDark: '#486581',
  darkModeMedium: '#627d98',
  darkModeMediumLight: '#829ab1',
  darkModeLight: '#9fb3c8',
  darkModeLighter: '#bcccdc',
  darkModeLightest: '#d9e2ec',
  darkModeBright: '#f0f4f8',
};
